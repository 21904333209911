import React from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import FlagGB from "../assets/icons/flags/gb.svg";
import FlagSK from "../assets/icons/flags/sk.svg";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 10px; */

  & svg {
    min-width: 20px;
    border-radius: 2px;
    height: 15px;
  }
`;

const Wrapper = styled.div`
  width: 80px;
  margin-left: -17px;
`;

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { changeLanguage } = useI18next();

  const languageOptions = [
    { value: "sk", label: "SK", icon: <FlagSK /> },
    { value: "en", label: "EN", icon: <FlagGB /> },
    // { value: "de", label: "DE", icon: <FlagDE /> },
  ];

  const { Option } = components;
  const CustomSelectOption = (props) => (
    <Option {...props}>
      <OptionRow>
        {props.data.icon && props.data.icon}
        <span
          style={{
            paddingLeft: props.data.icon ? 5 : 0,
          }}
        >
          {props.data.label}
        </span>
      </OptionRow>
    </Option>
  );

  const CustomSelectValue = (props) => (
    <OptionRow>
      {props.data.icon && props.data.icon}
      <span style={{ paddingLeft: props.data.icon ? 5 : 0 }}></span>
    </OptionRow>
  );

  return (
    <Wrapper>
      <Select
        styles={{
          //   option: (p, data) => ({
          //     ...p,
          //     cursor: "pointer",
          //     color: data.isSelected ? "#fff" : "#000",
          //   }),
          indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
            "& path": {
              fill: "#fff",
              transformOrigin: "center",
              transform: "scale(0.8)",
            },
            "& > div:nth-of-type(2)": {
              padding: 0,
            },
          }),
          //   indicatorContainer: (provided) => ({
          //     ...provided,
          //     padding: 0,
          //   }),
          indicatorSeparator: (provided) => ({
            display: "none",
          }),
          valueContainer: (p) => ({
            ...p,
            // display: "flex",
            // padding: "0",
            // minWidth: "23px",
            // flexWrap: "nowrap",
            "& input": {
              position: "absolute",
            },
            // marginRight: 0,
            // paddingRight: 0,
          }),
          control: (p) => ({
            ...p,
            cursor: "pointer",
            background: "none",
            border: 0,
            boxShadow: "none",
            padding: "0 8px",
            flexWrap: "nowrap",
          }),
          //   container: (p) => ({
          //     ...p,
          //     margin: "0 -10px",
          //     "@media (max-width: 960px)": {
          //       margin: 0,
          //     },
          //   }),
        }}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        isSearchable={false}
        value={languageOptions.find((option) => option.value === i18n.language)}
        options={languageOptions}
        onChange={(option) => {
          if (option !== null) changeLanguage(option.value);
        }}
      />
    </Wrapper>
  );
};

export default LanguageSelector;
