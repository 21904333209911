import React from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";

const OuterWrapper = styled.div`
  border-top: 1px solid #eee;
  width: 100%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  max-width: min(1024px, 80vw);
  margin: 40px auto 0;
  padding: 5vw 0;
  width: 100%;

  @media screen and (max-width: 1135px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

const Column = styled.div`
  font-size: 18px;
  line-height: 28px;

  color: #52553f;
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <OuterWrapper>
      <Wrapper>
        {/* <Link to="/">
        <svg
          width="203"
          height="61"
          viewBox="0 0 203 61"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 30.1804V0H101.5H203V30.1804V60.3608H101.5H0V30.1804ZM197.841 30.2099V4.23998H101.691H5.54158V30.2099V56.1797H101.691H197.841V30.2099Z"
            fill="#474B20"
          />
          <path
            d="M18.2172 30.2689V12.6023L20.7778 12.6141L23.3447 12.6317L32.6444 24.1268C38.1923 30.9874 41.9822 35.6219 42.0332 35.6101C42.0842 35.6042 46.027 30.4279 50.7914 24.1033L59.4605 12.6082L62.3459 12.6023H65.225V30.2689V47.9355H62.3587H59.4923V35.616C59.4923 24.0208 59.4987 23.1728 59.6197 21.3473C59.7726 19.0271 59.7726 18.9623 59.6707 18.9623C59.6261 18.9623 59.2949 19.4982 58.9318 20.1577C58.5751 20.8114 58.1038 21.6123 57.8808 21.9421C57.4859 22.525 42.9377 42.0938 42.753 42.2999C42.6702 42.3823 42.5428 42.4 41.9185 42.4L41.1797 42.3941L33.4724 32.701C29.2366 27.3716 25.5741 22.7547 25.3448 22.4426C24.7524 21.6535 24.1728 20.6995 23.8097 19.9339C23.625 19.5394 23.4657 19.2803 23.402 19.2685C23.2874 19.2449 23.2874 19.1507 23.453 20.6701C23.4976 21.0764 23.5294 25.8876 23.5485 34.6149L23.574 47.9355H20.8988H18.2172V30.2689Z"
            fill="#474B20"
          />
          <path
            d="M85.2256 30.2632V12.5967L97.3788 12.6202C110.265 12.6438 110.366 12.6438 112.774 12.9265C117.125 13.4447 119.959 14.4634 122.488 16.4185C125.775 18.9566 128.074 23.0199 128.698 27.36C128.928 28.9617 128.928 31.2996 128.698 32.9485C128.138 36.9705 126.392 40.5038 123.698 43.0714C120.367 46.2513 116.418 47.5528 109.175 47.8767C108.354 47.912 103.309 47.9355 96.5189 47.9355H85.2256V30.2632ZM107.838 42.9889C111.481 42.8005 114.207 42.2587 116.373 41.3047C119.59 39.8796 121.469 37.2885 122.106 33.4019C122.392 31.6353 122.437 29.0206 122.201 27.3835C121.647 23.4792 119.927 20.8587 116.921 19.3453C115.086 18.4207 112.545 17.7906 109.558 17.5197C107.787 17.3607 105.462 17.3195 98.398 17.3136H91.5952V30.1807V43.0478H99.1623C103.328 43.0478 107.226 43.0184 107.838 42.9889Z"
            fill="#474B20"
          />
          <path
            d="M136.864 47.4173C137.03 47.1288 141.724 39.1788 147.298 29.7507L157.425 12.6023H160.801H164.177L174.77 30.2336C180.592 39.9326 185.356 47.8825 185.356 47.9002C185.356 47.9179 183.815 47.9355 181.936 47.9355H178.509L176.063 43.5778L173.617 39.22H160.616H147.616L145.164 43.5778L142.718 47.9355H139.635H136.558L136.864 47.4173ZM170.961 34.7327C170.954 34.5796 160.718 16.6951 160.636 16.6833C160.54 16.6656 150.387 34.5266 150.387 34.7032C150.387 34.7975 151.107 34.8034 160.674 34.8034C168.865 34.8034 170.961 34.7857 170.961 34.7327Z"
            fill="#474B20"
          />
        </svg>
      </Link> */}

        <Column>
          {t("footer.korespondencna-adresa")}
          <br />
          Michal Diviš Architekti, s.r.o.
          <br />
          Svätoplukova 24, 010 01 Žilina
        </Column>

        <Column>
          Office: EUROPALACE
          <br />
          Vysokoškolákov 33/B
          <br />
          010 08 Žilina
        </Column>
        <Column>
          IČO: 36440191
          <br />
          DIČ: 2022140494
          <br />
          IČDPH: SK2022140494
        </Column>
        <Column>
          MD: +421 911 698 330
          <br />
          {t("footer.atelier")}: +421 911 698 331
          <br />
          mda@mda.sk
        </Column>
      </Wrapper>
    </OuterWrapper>
  );
};

export default Footer;
