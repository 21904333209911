import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const LinkHelper = ({ to, children, onClick }) => {
  const { i18n } = useTranslation();
  return (
    <Link
      to={to}
      language={i18n.language}
      onClick={typeof onClick === "function" ? onClick : undefined}
    >
      {children}
    </Link>
  );
};

export default LinkHelper;
