import React from "react";
import Link from "../helpers/Link";
import styled from "styled-components";
import LanguageSelector from "../components/LanguageSelector";
import Logo from "../assets/logo1.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const StyledHeader = styled.header`
  position: absolute;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.5s ease;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;

    & nav ul {
      padding-left: 32px;
    }
  }
  @media screen and (max-width: 485px) and (min-width: 361px) {
    & .facebook,
    & .instagram {
      position: absolute;
      right: 30px;
      top: 20px;
    }

    & .facebook {
      right: 70px;
    }
  }

  @media screen and (max-width: 360px) {
    & .instagram {
      position: absolute;
      left: 75px;
      top: 80px;
    }
  }

  &.active {
    position: static;
  }

  .logo-wrapper {
    margin-right: auto;
    padding: 10px;

    & .logo {
      max-height: 50px;
    }
  }

  .logo {
    [fill] {
      fill: #fff;
      transition: fill 0.5s ease;
    }
    [stroke] {
      stroke: #fff;
      transition: stroke 0.5s ease;
    }
  }

  nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 360px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }

  li svg.social path {
    transition: fill 0.5s ease;
  }

  li a {
    transition: color 0.5s ease;
    color: #fff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  li > *:last-child span + div > svg path {
    transition: fill 0.5s ease;
  }

  &:hover,
  &.active {
    background: #fff;
    .logo {
      [fill] {
        fill: #000;
      }
      [stroke] {
        stroke: #000;
      }
    }
    li > *:last-child span + div > svg path {
      fill: #000 !important;
    }

    li a {
      color: #000;
    }

    li svg.social path {
      fill: #000;
    }
  }
`;

const Header = ({ meta }) => {
  const { t } = useTranslation();

  return (
    <StyledHeader
      className={
        /\/projekty\//.test(meta?.location?.pathname) ||
        /\/atelier\//.test(meta?.location?.pathname) ||
        /\/typologia\//.test(meta?.location?.pathname)
          ? "active"
          : ""
      }
      // className={/^.{1,4}$/g.test(typeof window !== "undefined" ? window.location.pathname : "") ? "" : "active"}
    >
      <div className="logo-wrapper">
        <Link to="/">
          <Logo className="logo" style={{ cursor: "pointer", display: "block" }} />
        </Link>
      </div>
      <nav>
        <ul>
          <li className="facebook">
            <a href="https://www.facebook.com/michaldivisarchitekti/" target="_blank">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: "translateY(4px)",
                }}
                className="social"
              >
                <g clip-path="url(#clip0_2984_11141)">
                  <path
                    d="M19.3506 3H4.64941C3.75 3 3 3.75 3 4.64941V19.3506C3 20.25 3.75 21 4.64941 21H12V13.5H9.75V11.25H12V9C12 7.125 12.75 6 15 6H17.25V8.25H16.2744C15.6006 8.25 15 8.85059 15 9.52441V11.25H18L17.625 13.5H15V21H19.3506C20.25 21 21 20.25 21 19.3506V4.64941C21 3.75 20.25 3 19.3506 3Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2984_11141">
                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </li>
          <li className="instagram">
            <a href="https://www.instagram.com/mda.works/?hl=en" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                style={{
                  transform: "translateY(4px)",
                }}
                className="social"
              >
                <path
                  d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"
                  fill="#fff"
                />
              </svg>
            </a>
          </li>
          <li>
            <Link to="/">{t("header.domov")}</Link>
          </li>
          <li>
            <Link to="/projekty/">{t("header.projekty")}</Link>
          </li>
          <li>
            <Link to="/atelier/">{t("header.atelier")}</Link>
          </li>
          <li>
            <LanguageSelector />
          </li>
        </ul>
      </nav>
      <hr />
    </StyledHeader>
  );
};

export default Header;
