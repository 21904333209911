import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./sections/Footer";

import { Helmet } from "react-helmet";
import "../css/reset.css";
import "../css/index.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Layout = ({ children, meta }) => {
  return (
    <Wrapper
      onMouseDown={(e) => {
        if (e.target.tagName === "IMG") {
          e.preventDefault();
        }
      }}
      onContextMenu={(e) => {
        if (e.target.tagName === "IMG" || e.target.classList.contains("gatsby-resp-image-image")) {
          e.preventDefault();
          return false;
        }
      }}
    >
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <title>Michal Diviš Architekti | MDA</title>
      </Helmet>
      <Header meta={meta} />
      <main>{children}</main>
      <Footer meta={meta} />
    </Wrapper>
  );
};

export default Layout;
